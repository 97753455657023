<template>
  <v-app>
    <AppBar/>
    <v-main>
      <RouterView/>
    </v-main>
  </v-app>
</template>

<script>
import config from './configs'
import AppBar from './components/AppBar'
import { mapGetters } from 'vuex'

export default {
  components: {
    AppBar
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  computed: {
    ...mapGetters({
      theme: 'reseller/theme'
    })
  },
  created() {
    this.$vuetify.theme.themes.light.primary = this.theme.primary
    this.$vuetify.theme.themes.light.secondary = this.theme.secondary
  }
}
</script>

<style>
.charcoal-text {
  color: #666666 !important;
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
   url(./fonts/DancingScript/DancingScript-Regular.ttf) format("truetype");
}
</style>
