<template>
  <div>
    <v-dialog
      v-model="withdrawDialog"
      max-width="500"
    >
      <v-card style="width: 100%;">

        <v-card-title class="headline">
          <v-row justify="center">
            <v-col cols="auto">
              Confirm Claim Withdraw
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <div class="content-wrapper">
            Please provide a reason for withdrawing this claim.

            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-textarea
                v-model="form.claim_withdraw_reason"
                outlined
                maxlength="2000"
                name="input-7-4"
                :rules="emptyRules.claim_withdraw_reason"
                style="width: 100%;"
              ></v-textarea>
            </v-form>
          </div>
            
        </v-card-text>
        <v-card-text v-if="errorText" class="red--text">
          {{ errorText }}
        </v-card-text>
        <v-card-actions>
          
          <v-row justify="center" no-gutters>
            <v-col cols="auto">
              <v-btn color="primary" outlined class="mr-2" @click="cancel">
                Cancel
              </v-btn>
              <v-btn color="primary darken-1" @click="onSubmitClick">
                Withdraw
              </v-btn>
            </v-col>
          </v-row>
          <v-progress-circular
            v-if="isSubmitting"
            indeterminate
            color="primary"
            :size="24"
            :width="2"
            class="ml-2"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="300px">
      <v-card>
        <v-card-title>Confirm Submission</v-card-title>
        <v-card-text>Are you sure you want to withdraw this claim? This cannot be undone.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="onConfirmNo">No</v-btn>
          <v-btn color="primary" @click="onConfirmYes">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ConfirmWithdraw',
  data: () => ({
    withdrawDialog: false,
    confirmDialog: false,
    isSubmitting: false,
    valid: true,
    errorText: '',
    form:{
      claim_withdraw_reason: ''
    },
    emptyRules: {
      claim_withdraw_reason: []

    },

    rules: {
      claim_withdraw_reason: [(v) => !!v || 'Reason Required']
    }
  }),
  computed: {
    ...mapState({
      claimState: (state) => state.claim.claim,
      claimStateToken: (state) => state.claim.token
    })
  },
  methods: {
    ...mapActions({
      submitWithdraw: 'claim/submitWithdraw'
    }),

    cancel() {
      this.withdrawDialog = false
    },

    onSubmitClick() {
      // Open the confirmation dialog
      this.confirmDialog = true
    },

    onConfirmYes() {
      // Close the confirmation dialog
      this.confirmDialog = false

      // Submit the form
      this.submit()
    },

    onConfirmNo() {
      // Just close the confirmation dialog
      this.confirmDialog = false
    },

    // eslint-disable-next-line consistent-return
    async submit() {
      this.errorText = ''
      
      Object.keys(this.emptyRules).forEach((key) => {
        this.emptyRules[key] = this.rules[key]
      })
      
      await this.$refs.form.validate()

      if (this.valid) {
        this.isSubmitting = true
        // Submit the withdraw
        const result = await this.submitWithdraw(this.form)

        if (result) {
          //close the withdrawDialog
          this.withdrawDialog = false

          //After withdraw successfully submitted, direct to withdraw page
          return this.$router.push(`/claim/${this.claimStateToken}/withdraw`)
        } else {
          this.errorText = 'Error processing withdrawal'
        }
        this.isSubmitting = false
      }
    }
  }
}

</script>
<style scoped>
  .content-wrapper {
    width: 100%;
    overflow-x: hidden;
  }

  /* Ensure all direct children of content-wrapper respect its width */
  .content-wrapper > * {
    max-width: 100%;
  }
</style>
