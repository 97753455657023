export default {
  namespaced: true,
  
  state: {
    token: null,
    policy: null,
    loading: false,
    errors: null
  },
  
  getters: {
    hasErrors(state) {
      return state.errors.length > 0
    }

  },
  
  mutations: {

    setError(state, value) {
      state.errors = value
    },

    setToken(state, value) {
      state.token = value
    },

    setPolicy(state, value) {
      state.policy = value
    },

    setLoading(state, value) {
      state.loading = value
    }

  },
  
  actions: {
    async getToken ({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      //API-TODO: send request
      await new Promise( (resolve) => 
        setTimeout(resolve, 2000)
      )
            
      if (data.certificate === '1234' && data.email === 'jack@test.com') {
        commit('setToken', '1H2618BNAKJ281JA81H4N1K-NEW')  
      }
      else {
        commit('setError', { message : 'policy not found' } ) 
        commit('setToken', null)  
      }  
            
      commit('setLoading', false) 
    },

    async getPolicy ({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)
            
      //API-TODO: send request
      await new Promise( (resolve) => 
        setTimeout(resolve, 2000)
      )

      if (data.token === '1H2618BNAKJ281JA81H4N1K-NEW') {
        commit('setPolicy', { PolicyId: 5000, firstName: 'Jack', lastName: 'Murray (Policy)' }) 
        commit('claim/setClaim', { ClaimType: 1 }, { root: true }) 
      }
      else {
        commit('setError', { message : 'invalid token' } ) 
        commit('setToken', null) 
        commit('setPolicy', null)  
      }  
            
      commit('setLoading', false) 
    },

    async clearState({ commit }) {
      commit('setLoading', false)
      commit('setError', null)
      commit('setToken', null)
      commit('setPolicy', null)

    }
        
  }
}