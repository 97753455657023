import { CURRENCY } from '../constants/currency'

export default {
  namespaced: true,

  state: {
    claim: null,
    loading: false,
    errors: null,
    documents: [],
    token: null,
    loadingNext: false,
    stepName: null,
    audio: true,
    video: true,
    claimadmininfo: {},
    claimlimits: {},
    claimsArray: {}
  },

  getters: {
    hasErrors(state) {
      return state.errors !== null && state.errors.length > 0
    },
    newClaim(state) {
      return state.claim.claim_id === '0'
    },

    hasInvoice(state) {
      for (const doc in state.claim.documents) {
        if (state.claim.documents[doc].doc_type === 'Invoice/Receipt') {
          return true
        }
      }

      return false
    },

    hasImage(state) {
      for (const doc in state.claim.documents) {
        if (state.claim.documents[doc].doc_type === 'Photo/Image of Damage') {
          return true
        }
      }

      return false
    },

    hasPoliceReport(state) {
      for (const doc in state.claim.documents) {
        if (state.claim.documents[doc].doc_type === 'Police Report') {
          return true
        }
      }

      return false
    },

    hasRecording(state) {
      for (const doc in state.claim.documents) {
        if (state.claim.documents[doc].doc_type === 'Recording') {
          return true
        }
      }

      return false
    },

    hasDetails(state) {
      const details = [
        'claim_location_address',
        'claim_location_city',
        'claim_location_country',
        'claim_location_state',
        'claim_location_zip',
        'claim_nature_incident',
        'claim_reported_by',
        'claim_reported_on',
        'claim_type',
      ]

      for (const detail in details) {
        if (state.claim[detail] || state.claim[detail] === '') {
          return false
        }
      }

      return true
    },

    getCurrency(state) {
      // If claim is not set... use default
      if (!state.claim) return CURRENCY.find((c) => c.currency === 'default')

      // Search for currency based on the currency in the state
      const curr = CURRENCY.find((c) => c.currency === state.claim.currency)

      // No currency found retrun the default currency info
      if (!curr) return CURRENCY.find((c) => c.currency === 'default')

      return curr
    },

    canCheck(state) {
      return {
        edit: state.claim.claim_CanEdit === '1',
        open: state.claim.claim_CanOpen === '1',
        withdraw: state.claim.claim_CanWithdrawal === '1',
        doc: state.claim.claim_AllowNewDocuments === '1',
        submit: state.claim.claim_CanSubmit === '1',
      }
    },

    recording(state) {
      const recordType = 'Recording'

      return state.documents.find((doc) => doc.doc_type === recordType)
    },

    noInputDevice(state) {
      return !state.audio && !state.video
    },

    isTravelInsurance(state) {
      return state.claim ? state.claim.policy_coverage_type === 'TI' : ''
    },
  },

  mutations: {
    setError(state, value) {
      state.errors = value
    },

    setLoading(state, value) {
      state.loading = value
    },

    setClaim(state, value) {
      state.claim = value
    },

    setToken(state, value) {
      state.token = value
    },

    addDocument(state, value) {
      state.documents.push(value)
    },

    setDocuments(state, value) {
      state.documents = value
    },

    updateClaim(state, value) {
      //If no data has been added to the data Object, overwrite it
      if (state.claim === null) {
        state.claim = value
      }
      //If there is already data in the data Object, merge the new data into it
      else {
        state.claim = { ...state.claim, ...value }
      }
    },

    setLoadingNext(state, value) {
      state.loadingNext = value
    },

    setStepName(state, value) {
      state.stepName = value
    },

    setAudio(state, value) {
      state.audio = value
    },

    setVideo(state, value) {
      state.video = value
    },

    setAdminInfo(state, value) {
      state.claimadmininfo = value
    },

    setClaimLimits(state, value) {
      state.claimlimits = value
    },

    setClaimsArray(state, value) {
      state.claimsArray = value
    }
  },

  actions: {
    async getToken({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/find-claim-token/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      )

      const responseBody = await response.json()

      //If we have a ClaimToken with data, set the token so we can go to the step-wizard
      if (responseBody.ClaimToken) {
        commit('setToken', responseBody.ClaimToken)
      } else {
        commit('setError', { message: 'claim not found' })
        commit('setToken', null)
      }

      commit('setLoading', false)
    },

    async getNewToken({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/get-new-token/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      )

      const responseBody = await response.json()

      //If we have a ClaimToken with data, set the token so we can go to the step-wizard
      if (responseBody.token) {
        commit('setToken', responseBody.token)
      } else {
        commit('setError', { message: 'unable to generate token' })
        commit('setToken', null)
      }

      commit('setLoading', false)
    },

    async getClaimLimits({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/get-claim-limits/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      ).catch(() => {
        commit('setError', { Message: 'Internal Error' })
      })
      
      const responseBody = await response.json()

      if (responseBody.coverageId > 0) {
        commit('setClaimLimits', responseBody)
      } else {
        commit('setError', { message: 'Coverage not found' })
        commit('setClaimLimits', {})
      }

      commit('setLoading', false)
    },

    async getClaimsByCoverage({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/get-claims-by-coverage/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      ).catch(() => {
        commit('setError', { Message: 'Internal Error' })
      })
      
      const responseBody = await response.json()

      if (responseBody.claims) {
        commit('setClaimsArray', responseBody)
      } else {
        commit('setError', { message: 'Claims not found' })
        commit('setClaimsArray', {})
      }

      commit('setLoading', false)
    },

    async getClaimAdminInfo({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/get-admin-info/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      ).catch(() => {
        commit('setError', { Message: 'Internal Error' })
      })

      const responseBody = await response.json()

      if (responseBody.claim_company) {
        commit('setAdminInfo', responseBody)
      } else {
        commit('setError', { message: 'Claim company not found' })
        commit('setAdminInfo', {})
      }

      commit('setLoading', false)
    },

    async getClaim({ commit }, data) {
      commit('setLoading', true)
      commit('setError', null)

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/claim-data/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      ).catch(() => {
        commit('setError', { message: 'internal error' })
        commit('setClaim', null)
        commit('setLoading', false)
      })

      if (!response) return

      const responseBody = await response.json()

      if (!responseBody.Message) {
        commit('setClaim', responseBody)
        commit('setDocuments', responseBody.documents)
      } else {
        commit('setError', { message: 'invalid token' })
        commit('setToken', null)
        commit('setClaim', null)
      }

      commit('setLoading', false)
    },

    async submitWithdraw({ state }, data) {
      const payload = {
        token: state.token,
        claim_id: state.claim.claim_id,
        claim_withdraw_reason: data.claim_withdraw_reason,
      }

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/withdraw-claim/',
        {
          method: 'post',
          body: JSON.stringify(payload),
        }
      ).catch(() => {})

      if (response.status !== 200) return

      const responseBody = await response.json()

      // eslint-disable-next-line consistent-return
      return responseBody
    },
    async submitClaim({ commit, state }) {
      commit('setError', null)

      const payload = {
        token: state.token,
        user_id: 0,
        claim_id: state.claim.claim_id,
        claim_contact_company: state.claim.claim_contact_company,
        claim_contact_firstname: state.claim.claim_contact_firstname,
        claim_contact_lastname: state.claim.claim_contact_lastname,
        claim_contact_address: state.claim.claim_contact_address,
        claim_contact_city: state.claim.claim_contact_city,
        claim_contact_state: state.claim.claim_contact_state,
        claim_contact_country: state.claim.claim_contact_country,
        claim_contact_zip: state.claim.claim_contact_zip,
        claim_contact_phone: state.claim.claim_contact_phone,
        claim_contact_email: state.claim.claim_contact_email,
        claim_location_address: state.claim.claim_location_address,
        claim_location_city: state.claim.claim_location_city,
        claim_location_state: state.claim.claim_location_state,
        claim_location_zip: state.claim.claim_location_zip,
        claim_location_country: state.claim.claim_location_country,
        claim_reported_on: state.claim.claim_reported_on,
        claim_reported_by: state.claim.claim_reported_by,
        claim_type: state.claim.claim_type,
        claim_amount: state.claim.claim_amount,
        claim_nature_incident: state.claim.claim_nature_incident,
        claim_action_taken: state.claim.claim_action_taken,
        claim_comments: state.claim.claim_comments,
        claim_initials: state.claim.claim_initials
      }

      try {
        const response = await fetch(
          process.env.VUE_APP_DEFAULT_URL + '/submit-claim/',
          {
            method: 'post',
            body: JSON.stringify(payload),
          }
        ).catch(() => {
          commit('setError', { Message: 'Internal Error' })
        })

        const responseBody = await response.json()

        // Successful response clear errors
        if (
          responseBody.Message &&
          responseBody.Message === 'Request Successful'
        ) {
          commit('setError', null)
        } else {
          commit('setError', responseBody)
        }
      } catch (error) {
        commit('setError', { Message: error.message })
      }
    },

    async submitDoc({ commit }, data) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      // eslint-disable-next-line prefer-destructuring
      const base64File = (await toBase64(data.doc_file)).split('base64,')[1]

      const docUploadData = {
        token: data.token,
        doc_name: data.doc_name,
        doc_type: data.doc_type,
        doc_amount: data.doc_amount,
        doc_data: base64File,
        doc_filename: data.doc_file.name,
      }

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/submit-document/',
        {
          method: 'post',
          body: JSON.stringify(docUploadData),
        }
      ).catch({})

      const responseBody = await response.json()

      const result = { ...data, ...{ doc_id: responseBody.doc_id } }

      commit('addDocument', result)

      //const responseBody = await response.json();
      return true
    },

    async postSingleDoc({ commit }, data) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      // eslint-disable-next-line prefer-destructuring
      const base64File = (await toBase64(data.doc_file)).split('base64,')[1]

      const docUploadData = {
        token: data.token,
        doc_name: data.doc_name,
        doc_type: data.doc_type,
        doc_amount: data.doc_amount,
        doc_data: base64File,
        doc_filename: data.doc_file.name,
      }

      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/post-document/',
        {
          method: 'post',
          body: JSON.stringify(docUploadData),
        }
      ).catch({})

      const responseBody = await response.json()

      const result = { ...data, ...{ doc_id: responseBody.doc[0].id } }

      commit('addDocument', result)

      return true
    },

    async deleteDoc({ commit, state }, data) {
      const response = await fetch(
        process.env.VUE_APP_DEFAULT_URL + '/remove-document/',
        {
          method: 'post',
          body: JSON.stringify(data),
        }
      ).catch({})

      response
      //const responseBody = await response.json();

      const documents = state.documents.filter(
        (doc) => doc.doc_id !== data.doc_id
      )

      commit('setDocuments', documents)

      return true
    },

    async clearState({ commit }) {
      commit('setLoading', false)
      commit('setError', null)
      commit('setToken', null)
      commit('setClaim', null)
      commit('setDocuments', null)
      commit('setStepName', null)
      commit('setAdminInfo', {})
      commit('setClaimLimits', {})
      commit('setClaimsArray', {})
    },

    async clearClaim({ commit }) {
      commit('setLoading', false)
      commit('setError', null)
      commit('setToken', null)
      commit('setClaim', null)
      commit('setDocuments', null)
      commit('setStepName', null)
    }
  },
}