import Vue from 'vue'
import Vuex from 'vuex'
import policy from './policy'
import claim from './claim'
import reseller from './reseller'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    policy,
    claim,
    reseller,
  }
})