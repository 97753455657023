import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'welcome-claim',
    component: () => import(/* webpackChunkName: "welcome-claim" */ '@/pages/WelcomeClaimPage.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "welcome-claim" */ '@/pages/ContactUsPage.vue')
  },
  {
    path: '/coverage/:coverageId',
    name: 'coverage-action',
    component: () => import(/* webpackChunkName: "welcome-claim" */ '@/pages/ClaimSelectionPage.vue')
  },
  {
    path: '/claim/:token/create',
    name: 'claim-token-create',
    component: () => import(/* webpackChunkName: "claim-token" */ '@/pages/ClaimPage.vue')
  },
  {
    path: '/claim/:token',
    name: 'claim-token',
    component: () => import(/* webpackChunkName: "claim-token" */ '@/pages/ClaimPage.vue')
  },
  {
    path: '/claim/:token/complete',
    name: 'claim-token-complete',
    component: () => import(/* webpackChunkName: "complete" */ '@/pages/CompletePage.vue')
  },
  {
    path: '/claim/:token/withdraw',
    name: 'claim-token-withdraw',
    component: () => import(/* webpackChunkName: "withdraw" */ '@/pages/WithdrawPage.vue')
  }]
}, {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
  if (to.path === '/' && from.path !== '/') {
    // Clear the history
    const baseUrl = window.location.origin + (process.env.BASE_URL || '/')
    
    window.history.pushState(null, '', baseUrl)
  }
})

export default router