import Vuetify from '../plugins/vuetify'

export default {
  namespaced: true,
  
  state: {
    settings: {},
  },
  
  getters: {
    theme(state) {
      return {
        primary: state.settings.primary_color || Vuetify.framework.theme.themes.light.primary,
        secondary: state.settings.secondary_color || Vuetify.framework.theme.themes.light.secondary
      }
    },
    claimVideoEnabled(state) {
      return state.settings.claim_video_enabled !== 0
    },
    claimVideoRequired(state) {
      return state.settings.claim_video_required !== 0
    },
    docSubmissionInReview(state) {
      return state.settings.doc_submission_inreview !== 0
    }
  },
  
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
  },
  
  actions: {
    async getSettings( { commit } ) {
      const url = window.location.href.includes('localhost')
        ? process.env.VUE_APP_DEFAULT_RESELLER_URL // use the default reseller url in local environment
        : window.location.hostname

      const response = await fetch(process.env.VUE_APP_DEFAULT_URL + '/get-claim-settings/', {
        method: 'post',
        body: JSON.stringify({ url })
      }).catch(() => {})

      if (!response) return

      const responseBody = await response.json()

      commit('setSettings', {
        ...responseBody,
        is_default: false,
      })
      
    },

  }
}